import React, { useContext, useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import {
  enrichStoryWithGatsbyImages,
  getBlok,
  GReactMD,
} from "../components/utils/storyblok";
import Seo from "../components/layout/seo";
import {
  getPageSlug,
  prefixUrl,
  suffixTitle,
} from "../components/utils/no-import-commons";
import Title from "../components/bloks/title";
import { LanguageContext } from "../components/context/language-context";
import itFl from "../resources/assets/it-fl.svg";
import enFl from "../resources/assets/en-fl.png";
import useWindowSize from "../components/utils/use-window-size";

const lng2Flag = {
  it: itFl,
  en: enFl,
};

const translationIds = {
  "level-1": "cal_level_1_course",
  "level-2": "cal_level_2_course",
  "level-3": "cal_level_3_course",
  "bridal": "cal_bridal_course",
  "hairstyle": "cal_hairstyle_course",
  "bridal-path": "cal_bridal_path_course",
  "special-effects": "cal_sfx_course",
  "full-time": "cal_fulltime_course",
  sabato: "cal_saturday_course",
  domenica: "cal_sunday_course",
  weekend: "cal_we_course",
  it: "filter_it_language",
  en: "filter_en_language",
};

const formatDate = (date) => {
  return new Date((date ?? "").replace(/-/g, "/")).toLocaleDateString();
};

const Calendar = ({ data, pageContext }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filter, setFilter] = useState({});

  const story = data.storyblokEntry;
  if ("content" in story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  enrichStoryWithGatsbyImages(story, data?.images?.nodes ?? []);

  const FILTERS = story?.content?.calendario.reduce((obj, elem) => {
    const insElem = (key, elem) => {
      obj[key] = obj[key] || [];
      obj[key].includes(elem) || obj[key].push(elem);
    };

    insElem("corso", elem.corso);
    insElem("lingua", elem.lingua);
    insElem("frequenza", elem.frequenza);
    insElem("data_inizio", elem.data_inizio);
    insElem("data_fine", elem.data_fine);
    return obj;
  }, {});

  return (
    <Layout lm={pageContext.lm}>
      {story?.content?.title_blok?.[0] && (
        <Title b={story.content.title_blok[0]} />
      )}
      {/* TODO: blok calendar */}
      <FilterCalendar
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        setFilter={setFilter}
        FILTERS={FILTERS}
        title={story?.content?.titolo_pre_filtri}
        text={story?.content?.testo_pre_filtri}
      />
      <div className="mt-10">
        <div className="mud-cont pb-6">
          {story?.content?.titolo_pre_calendario && (
            <h2 className="mud-h3-nolg pb-2">
              {story.content.titolo_pre_calendario}
            </h2>
          )}
          {story?.content?.testo_pre_calendario && (
            <GReactMD className="pt-2 pb-4">
              {story.content.testo_pre_calendario}
            </GReactMD>
          )}
        </div>
        {(story?.content?.calendario ?? []).map((cal) =>
          getCalendar(cal, filter)
        )}
      </div>
      {(story?.content?.content ?? []).map(getBlok)}
    </Layout>
  );
};

const getCalendar = (calendar, filter) => {
  let isFiltered = false;
  Object.keys(filter).length > 0 &&
    Object.keys(filter).forEach((key) => {
      let filterValue = filter[key];
      let calendarValue = calendar[key];
      switch (key) {
        case "data_inizio": {
          if (
            filterValue &&
            Date.parse(filterValue) >= Date.parse(calendarValue)
          ) {
            isFiltered = true;
          }
          return isFiltered;
        }
        case "data_fine": {
          if (
            filterValue &&
            Date.parse(filterValue) >= Date.parse(calendarValue)
          ) {
            isFiltered = true;
          }
          return isFiltered;
        }
        default: {
          if (filterValue !== calendarValue && filterValue !== "all") {
            isFiltered = true;
          }
          return isFiltered;
        }
      }
    });

  return !isFiltered && <Calendarblock b={calendar} key={calendar._uid} />;
};

const FilterCalendar = ({
  setFilter,
  isFilterOpen = false,
  setIsFilterOpen,
  FILTERS,
  title,
  text,
}) => {
  const { t } = useContext(LanguageContext);
  const { isMobile } = useWindowSize();

  const updateField = (e) => {
    setFilter((data) => ({
      ...data,
      [e.target.name]: e.target?.value,
    }));
  };

  const FILTERSTYPES = Object.keys(FILTERS).map((f) => {
    return {
      key: f,
      data: FILTERS[f],
      header: `header_filter_${f}`,
      default: `default_filter_${f}`,
      isDate: ["data_inizio", "data_fine"].includes(f),
    };
  });

  return (
    <section className="bg-[#F0B33E]">
      <div className="mud-cont">
        {title && <h2 className="mud-h3-nolg pt-4 pb-1">{title}</h2>}
        {text && <GReactMD className="pt-1 pb-4">{text}</GReactMD>}
      </div>
      <button
        className="lg:hidden bg-[#ffffff66] btn border-black mx-5 my-5"
        aria-expanded={isFilterOpen}
        aria-controls="cal-filters"
        onClick={() => {
          setIsFilterOpen((m) => !m);
        }}
      >
        {isFilterOpen ? <Close /> : t.calendar_filters_mobile}
      </button>
      <div
        id="cal-filters"
        className="mud-cont lg:mud-grid-filter items-end gap-4 pb-5 pt-2"
        style={{
          display: isMobile ? (isFilterOpen ? "block" : "none") : "grid",
        }}
      >
        {(FILTERSTYPES ?? []).map((el) => {
          return el.isDate ? (
            <div className="flex flex-col" key={el.key}>
              <h4>{t[el?.header]}</h4>
              <input
                className="bg-[#ffffff66] btn border-black"
                type="date"
                id={el.key}
                name={el.key}
                onChange={updateField}
              ></input>
            </div>
          ) : (
            <div className="flex flex-col" key={el.key}>
              <h4>{t[el?.header]}</h4>
              <select
                id={el.key}
                onChange={updateField}
                defaultValue="all"
                name={el.key}
                className="bg-[#ffffff66] btn border-black"
              >
                <option value="all">{t[el?.default] || ""}</option>
                {(el?.data ?? []).map((item) => (
                  <option value={item} key={item}>
                    {t[translationIds[item]] || ""}
                  </option>
                ))}
              </select>
            </div>
          );
        })}
      </div>
    </section>
  );
};

const Calendarblock = ({ b }) => {
  const { t } = useContext(LanguageContext);
  return (
    <section className="lg:mud-cont">
      <div className="bg-[#EEEEEE] px-5">
        <div
          //className="flex flex-col lg:flex-row lg:py-5"
          className="grid grid-cols-1 grid-rows-4 lg:mud-cal-grid-lg lg:py-5"
          style={{
            gridTemplateRows: "auto",
            alignItems: "center",
            opacity: b?.posti_esauriti ? "0.2" : "1",
          }}
        >
          <h3 className="font-bold uppercase py-2 order-1">
            {t[translationIds[b?.corso]] || ""}
          </h3>
          <div className="py-2 lg:pr-6 lg:pl-3 order-2 lg:order-3">
            <img src={lng2Flag[b?.lingua] || itFl} alt="" width={27} />
          </div>
          <p className="py-2 lg:pr-3 order-3 lg:pl-6 lg:order-2">
            {`${t.calendar_date_from} ${formatDate(b?.data_inizio)} ${
              t.calendar_date_to
            } ${formatDate(b?.data_fine)}`}
          </p>
          <p className="py-2 order-4 lg:text-right">
            {t[translationIds[b?.frequenza]] || ""}
          </p>
        </div>
      </div>
      <div className="px-5 lg:px-0">
        <p className="min-h-[40px] py-2 text-[#DD2E44]">
          {b?.posti_esauriti && t.calendar_sold_out}
          {b?.pochi_posti_rimasti &&
            !b?.posti_esauriti &&
            t.calendar_almost_sold_out}
        </p>
      </div>
    </section>
  );
};

const Close = () => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
    <path
      d="M19.0001 1.80088L2.66553 18.1355"
      className="stroke-current"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M13.1672 13.0477L1.92041 1.8009"
      className="stroke-current"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M18.3024 18.1828L11.6079 11.4883"
      className="stroke-current"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);

export const Head = ({ data, pageContext }) => {
  const story = data.storyblokEntry;
  if ("content" in story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  const seoInfo = story?.content?.page_information[0];
  seoInfo.url = prefixUrl(getPageSlug(seoInfo.slug, story.lang));
  seoInfo.title = suffixTitle(seoInfo?.title ?? "");
  const lm = { ...(pageContext?.lm ?? {}) };
  for (const k in lm) {
    lm[k] = prefixUrl(lm[k]);
  }

  return <Seo info={seoInfo} lm={lm} />;
};

export const query = graphql`
  query ($id: String!, $images: [String]!) {
    storyblokEntry(id: { eq: $id }) {
      field_component
      content
      lang
      name
      slug
    }
    images: allFile(filter: { base: { in: $images } }) {
      nodes {
        base
        publicURL
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;

export default Calendar;
